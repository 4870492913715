import { AuthorizedService } from "../index";

const baseTicket = "/plugins/c/pa/processes/handlepermissionsapproval/";
const BaseTicketService = url => AuthorizedService(url);
class PermissionsRequestTicketService {
  constructor() {
    this.ticketService = BaseTicketService(baseTicket);
  }
  getDetails(id) {
    return this.ticketService.get(`/${id}/`);
  }
  getList(params) {
    return this.ticketService({
      method: "GET",
      params
    });
  }
  getListMetadata() {
    return this.ticketService({
      method: "OPTIONS"
    });
  }
  CallAction(url, payload) {
    return this.ticketService({
      baseURL: url,
      method: "Post",
      data: payload
    });
  }
}

export default new PermissionsRequestTicketService();
