const state = {
  unprocessed_permissions_count: 0,
  new_operation: 0
};
const getters = {
  getUnprocessedCount: state => {
    return state.unprocessed_permissions_count;
  },
  getNewOperation: state => {
    return state.new_operation;
  }
};

const actions = {
  incrementUnprocessedCount({ commit }) {
    commit("incrementUnprocessedCount");
    commit("saveUnprocessedCount");
  },
  decrementUnprocessedCount({ commit }) {
    commit("decrementUnprocessedCount");
    commit("saveUnprocessedCount");
  },
  setUnprocessedCountToZero({ commit }) {
    commit("setUnprocessedCount", 0);
    commit("saveUnprocessedCount");
  },
  setUnprocessed({ commit }, value) {
    commit("setUnprocessedCount", value);
    commit("saveUnprocessedCount");
  },
  setNewOperation({ commit }, value) {
    commit("setNewOperation", value);
  }
};

const mutations = {
  incrementUnprocessedCount(state) {
    state.unprocessed_permissions_count++;
  },
  decrementUnprocessedCount(state) {
    state.unprocessed_permissions_count--;
  },
  setUnprocessedCount(state, value) {
    state.unprocessed_permissions_count = value;
  },
  setNewOperation(state, value) {
    state.new_operation = value;
  },
  saveUnprocessedCount(state) {
    localStorage.setItem(
      "unprocessed_permissions_count",
      state.unprocessed_permissions_count
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
