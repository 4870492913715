import axios from "axios";

import {
  authInterceptor,
  BASE_API_URL,
  API_ORIGIN,
  AuthorizedService
} from "./index";

export const authorizedReq = axios.create({
  params: { json: true }
});
authorizedReq.interceptors.request.use(...authInterceptor);

export const applyCommonActionWithUri = uri =>
  authorizedReq({
    method: "GET",
    url: `${BASE_API_URL}/${uri}`
  });

export const getUserDetails = () =>
  authorizedReq.get(BASE_API_URL + "/user/details");
export const loadTranslations = () =>
  axios.get(`${API_ORIGIN}/json-i18n?json=true`);
const pluginService = url => AuthorizedService(url);
const merchantService = pluginService("/config/");
export const getMerchantConfig = noAuthRequired =>
  merchantService({
    method: "GET",
    headers: {
      noAuthRequired
    }
  });
