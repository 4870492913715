import Vue from "vue";
import VueRouter from "vue-router";

import { i18n } from "./../plugins/i18n";
Vue.use(VueRouter);

const IS_ENABLED_KEYCLOAK = localStorage.getItem("isKeycloakAuth") === "true";

const routes = [
  {
    path: "/:lang/",
    exact: true,
    redirect: `/${i18n.locale}/dashboard`
  },
  {
    name: "login",
    path: "/:lang/login",
    exact: true,
    ...(IS_ENABLED_KEYCLOAK && {
      redirect: `/${i18n.locale}/dashboard`
    })
  },
  {
    name: "forget-password",
    path: "/:lang/forget-password",
    exact: true
    //redirect: `/${i18n.locale}/dashboard`
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
