<template>
  <v-snackbar v-model="show" :color="color">
    {{ text }}
    <a v-if="link" :href="link" target="_blank" @click="show = false">{{
      $t("Download")
    }}</a>
    <v-btn dark flat @click="show = false">{{ $t("Close") }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data: () => ({
    show: false,
    text: "",
    color: "info",
    link: null
  }),
  created() {
    this.$root.$on("snackbar", ({ text, color = "info", link }) => {
      this.text = text;
      this.color = color;
      this.show = true;
      this.link = link;
    });
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
  padding: 6px;
}
</style>
