import axios from "axios";

import { i18n } from "../plugins/i18n";
import router from "../router";
import { API_ORIGIN } from "../services";
export const getToken = () => {
  return localStorage.getItem("VUE_APP_TOKEN");
};
export const setToken = (token, refresh) => {
  localStorage.setItem("VUE_APP_TOKEN", token);
  localStorage.setItem("refresh", refresh);
};

let requestingToken = false; //this important so no multiple refreshToken request is done in parallel
//only one at the time
export const refreshToken = refresh => {
  return new Promise(resolve => {
    if (!requestingToken) {
      requestingToken = true;
      console.log("requesting new token.");
      return axios({
        baseURL: `${API_ORIGIN}/api/v1/user/token/refresh/`,
        method: "post",
        params: {
          format: "json"
        },
        data: { refresh },
        withCredentials: false
      })
        .then(res => {
          setToken(res.data.access, res.data.refresh);
          let d = new Date();
          d.setTime(d.getTime() + Number(process.env.VUE_APP_SESSION_IDLE));
          resolve();
        })
        .catch(e => {
          console.log("error while refreshing token", e);
          logout();
        })
        .finally(() => {
          requestingToken = false;
        });
    } else {
      setInterval(() => {
        if (!requestingToken) resolve();
      }, 100);
    }
  });
};
export const isLoggedIn = () => {
  const access_token = getToken();
  return !!access_token;
};
export const logout = async () => {
  const locale = i18n.locale || "en";
  localStorage.removeItem("VUE_APP_TOKEN");
  localStorage.removeItem("refresh");
  router.push(`/${locale}/login`);
};

export const parseJwt = token => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch {
    return JSON.parse("{}");
  }
};
